import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {listOrgMembers, searchOrgMembers} from 'Api/ZCalendar';
import {merge} from 'Utils';

const initialState = {
  'members': [],
  'lastestSearchMembers': [],
  'isLoading': false,
  'errorState': {
    isError: false,
    reason: '',
  },
  'kind': 'organization#members',
  'nextPageToken': '',
};

export const listOrganizationMembers = createAsyncThunk(
  'hostAppointments/listOrgMembers',
  async ({query}, thunkAPI) => {
    const response = await listOrgMembers(query);
    return response;
  }
);

export const searchOrganizationMembers = createAsyncThunk(
  'hostAppointments/searchOrgMembers',
  async ({query}, thunkAPI) => {
    const response = await searchOrgMembers(query);
    return response;
  }
);

export const organizationStore = createSlice({
  name: 'OrganizationStore',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(searchOrganizationMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      // upsert results
      state.members = merge(state.members, action.payload.items, 'userId');
      state.lastestSearchMembers = action.payload.items;
      state.kind = action.payload.kind;
      state.nextPageToken = action.nextPageToken;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(searchOrganizationMembers.pending, (state, action) => {
      state.isLoading = true;
      state.lastestSearchMembers = [];

      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(searchOrganizationMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.lastestSearchMembers = [];
      state.errorState = {
        isError: true,
        reason: '',
      };
    });
  },
});

export default organizationStore.reducer;
