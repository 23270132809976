import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';

import analyticsReducer from './AnalyticsStore';
import bookAppointmentsStoreReducer from './BookAppointmentsStore';
import bookingPageColorStoreReducer from './BookingPageColorStore';
import captchaStoreReducer from './CaptchaStore';
import errorStoreReducer from './ErrorStore';
import eventStoreReducer from './EventStore';
import GroupStoreReducer from './GroupStore';
import hostAppointmentsStoreReducer from './HostAppointmentsStore';
import hostBookingsStoreReducer from './HostBookingsStore';
import hostBusyEventsStoreReducer from './HostBusyEventsStore';
import HostConnectionHealthReducer from './HostConnectionHealthStore';
import hostSettingsStoreReducer from './HostSettingsStore';
import hostWorkflowsStoreReducer from './HostWorkflowsStore';
import integrationStoreReducer from './IntegrationStore';
import logoStoreReducer from './LogoStore';
import ManagedEventsReducer from './ManagedEventsStore';
import organizationSettingStoreReducer from './OrganizationSettingStore';
import organizationStoreReducer from './OrganizationStore';
import routingStoreReducer from './RoutingStore';
import snackbarStoreReducer from './SnackbarStore';
import TeamStoreReducer from './TeamStore';
import ThemeReducer from './ThemeStore';

import {hostApi} from 'services/schedulerInternal.ts';

export const reducer = {
  captchaState: captchaStoreReducer,
  errorState: errorStoreReducer,
  eventState: eventStoreReducer,
  hostAppointmentsState: hostAppointmentsStoreReducer,
  organizationState: organizationStoreReducer,
  hostBookingsState: hostBookingsStoreReducer,
  hostBusyEventsState: hostBusyEventsStoreReducer,
  hostWorkflowsState: hostWorkflowsStoreReducer,
  hostSettingsState: hostSettingsStoreReducer,
  hostConnectionHealthState: HostConnectionHealthReducer,
  snackbarState: snackbarStoreReducer,
  bookAppointments: bookAppointmentsStoreReducer,
  logoState: logoStoreReducer,
  bookingPageColorState: bookingPageColorStoreReducer,
  integrationState: integrationStoreReducer,
  analyticsState: analyticsReducer,
  organizationSettingState: organizationSettingStoreReducer,
  managedEventsState: ManagedEventsReducer,
  groupState: GroupStoreReducer,
  teamState: TeamStoreReducer,
  routingState: routingStoreReducer,
  themeState: ThemeReducer,
  [hostApi.reducerPath]: hostApi.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(hostApi.middleware),
});

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;

export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector as TypedUseSelectorHook<RootState>;
