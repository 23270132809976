import hybrid from '@zoom/hybrid-jssdk';
/**
 * init hybrid app bridge
 * All events must be registered here
 */
export async function initHybridAppBridge() {
  initHybridAppBridge.promise ??= hybrid.appBridge.config({
    subscribe: [
      {
        service: 'unifywebview',
        eventNames: ['DLEv_RecvWebviewMessage'],
      },
    ],
  });

  await initHybridAppBridge.promise;

  return hybrid.appBridge;
}
