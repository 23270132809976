import {
  compareAsc,
  differenceInMinutes,
  parseISO,
} from 'date-fns';
import dayjs from 'dayjs';
import {DateTime, Interval} from 'luxon';

import {
  APPT_POOLING_TYPE,
  APPT_INTERVAL_TYPE,
  DAY_KEYS,
  // DAY_KEYS,
  DAY_MINUTES,
  FORMAT_DAY,
  HOUR_MINUTES,
  PERIOD_TYPE,
  WEEKEND,
  AVAIL_STATUS,
  ADD_ON_TYPE,
  BOOKING_LIMIT_TIME_WINDOW,
  CONFLICT_CODE,
} from './consts';
import {getUserInfo} from './integration';
import Time from './Time';

import {toDateTime} from '../Components/BookingBlocksWidget/utils';

import {getStartOfWeek} from 'Utils';
import DateWithTimeZone from 'Utils/DateWithTimeZone';


/**
 * If there is new version of data(startDate: YYYY-MM-DD), we use new version data.
 * Otherwise, we use the old version of data (start.dateTime).
 * The existing data of old version will be updated by Sever side in the future.
 * @param {Object} appt
 * @return {string}
 */
export const getStartDate = (appt) => appt?.startDate ?? appt?.start?.dateTime;

/**
 * If there is new version of data(endDate: YYYY-MM-DD), we use new version of data.
 * Otherwise, we use the old version of data (end.dateTime).
 * The existing data of old version will be updated by Sever side in the future.
 * @param {Object} appt
 * @return {string}
 */
export const getEndDate = (appt) => appt?.endDate ?? appt?.end?.dateTime;

/**
 * If there is new version of data , we use new version of data.
 * Otherwise, we use the old version of data.
 * new version: (appt.availabilityRules?.[0].timeZone || appt.timeZone)
 * old version: (appt.start.timeZone)
 * appt.start.timeZone and appt.end.timeZone are same in the old version
 * The existing data of old version will be updated by sever side in the future.
 * @param {Object} appt
 * @return {string}
 */
export const getApptTimeZone = (appt) =>
  appt.availabilityRules?.[0].timeZone ?? (appt.timeZone ?? appt.start?.timeZone);

/**
 * If there is new version of data , we use new version of data.
 * Otherwise, we use the old version of data.
 * new version: appt.intervalType (the value is fixed/unlimited, unlimited means forever, fixed means having a range)
 * old version: appt.recurrence
 * The existing data of old version will be updated by sever side in the future.
 * @param {Object} appt
 * @return {string}
 */
export const getApptIntervalType = (appt) => appt?.intervalType ?? appt?.recurrence;

export const isInDayRange = (date, appt) => {
  const tz = date.zoneName;
  try {
    return Interval.fromDateTimes(
      toDateTime(getStartDate(appt), tz).startOf('day'),
      toDateTime(getEndDate(appt), tz).endOf('day'),
    ).contains(date);
  } catch {
    return false;
  }
};

/**
 *
 * @param {DateTime} date
 * @param {*} seg
 * @return {Boolean}
 */
export const isInSegDayRange = (date, seg) => {
  const tz = date.zoneName;
  try {
    return Interval.fromDateTimes(
      toDateTime(seg.start, tz).startOf('day'),
      // Want end of seg interval to be EXCLUSIVE; subtract 1 ms
      toDateTime(seg.end, tz).minus(1).endOf('day'),
    ).contains(date);
  } catch {
    console.error('invalid day range interval');
    return false;
  }
};


/**
 *
 * @param {*} appt
 * @return {Boolean}
 */
export const isApptRecurring = (appt) => {
  return !!getApptIntervalType(appt);
};

/**
 *
 * @param {*} appt
 * @return {Boolean}
 */
export const isTeamAppt = (appt) => {
  return Boolean(appt?.poolingType);
};

/**
 * If an appt is round-robin or collective but not created for a team, then it's anonymous,
 * without profile picture and display name.
 * @param {*} appt
 * @return {Boolean}
 */
export const isAnonymousTeamAppt = (appt) => {
  return appt?.user?.startsWith('d/');
};

/**
 *
 * @param {Appt} appt
 * @return {string}
 */
export const getAddOnType = (appt) => {
  if (isRRAppt(appt) && !isCollectiveAppt(appt)) {
    return ADD_ON_TYPE.OFFLINE;
  }
  return appt?.addOnType;
};

/**
 *
 * @param {*} apptType
 * @return {Boolean}
 */
export const isTeamApptType = (apptType) => {
  return Object.values(APPT_POOLING_TYPE).includes(apptType);
};

/**
 * Appt is roundRobin or has a round robin pool (multipool can show in collective results too)
 * @param {*} appt
 * @return {Boolean}
 */
export const isRRAppt = (appt) => {
  if (appt?.poolingType === APPT_POOLING_TYPE.ROUND_ROBIN) {
    return true;
  }
  if (appt?.poolingType === APPT_POOLING_TYPE.MULTI_POOL) {
    const hasRRPool = (appt?.userPools ?? [])
      .some((pool) => pool.poolingType === APPT_POOLING_TYPE.ROUND_ROBIN);
    return hasRRPool;
  }
  return false;
};

/**
 * Appt is collective or has a collective pool (multipool can show in rr results too)
 * @param {*} appt
 * @return {Boolean}
 */
export const isCollectiveAppt = (appt) => {
  if (appt?.poolingType === APPT_POOLING_TYPE.COLLECTIVE) {
    return true;
  }
  if (appt?.poolingType === APPT_POOLING_TYPE.MULTI_POOL) {
    const hasCollectivePool = (appt?.userPools ?? [])
      .some((pool) => pool.poolingType === APPT_POOLING_TYPE.COLLECTIVE);
    return hasCollectivePool;
  }
  return false;
};


const getDefaultWeekdaySegment = () => {
  // initially populate to 9 am - 5 pm in local time
  const startDate = new Time(9);
  const endDate = new Time(17);
  return [
    {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    },
  ];
};

const getCurrentSegmentsRecur = (arr) => {
  if (!arr) {
    return [];
  }
  const out = [];
  for (let i = 0; i < arr.length; i++) {
    const [startHour, startMinute] = arr[i].start.split(':');
    const [endHour, endMinute] = arr[i].end.split(':');
    const startingTime = new Time(startHour, startMinute);
    const endingTime = new Time(endHour, endMinute);
    out.push({
      start: startingTime.toISOString(),
      end: endingTime.toISOString(),
    });
  }
  return out;
};

// {
//   Sun: {
//     isChecked: false,
//     segments: [],
//   },
//   Mon: {
//     isChecked: true,
//     segments: getDefaultRecurringSegments(),
//   },
//   Tue: {
//     isChecked: ...,
//     segments: ...,
//   },
//   ...
// }
export const getRecurringApptDefaultSegments = () => Object.fromEntries(Object.keys(FORMAT_DAY).map(
  (dayOfWeek) => [
    dayOfWeek, // Object keys
    { // Object values
      isChecked: !WEEKEND.includes(dayOfWeek),
      segments: WEEKEND.includes(dayOfWeek) ?
        [] :
        getDefaultWeekdaySegment(),
    },
  ]
));

// {
//   Sun: {
//     isChecked: !!appointment?.segmentsRecurrence?.Sun && !!appointment?.segmentsRecurrence?.Sun?.length,
//     segments: getCurrentSegmentsRecur(appointment?.segmentsRecurrence?.Sun),
//   },
//   Mon: {
//     isChecked: ...,
//     segments: ...,
//   },
//   ...
// }
export const getRecurringApptSegments = (segmentsRecurrence) => Object.fromEntries(Object.keys(FORMAT_DAY).map(
  (dayOfWeek) => [
    dayOfWeek, // Object keys
    { // Object values
      // eslint-disable-next-line max-len
      isChecked: !!segmentsRecurrence?.[dayOfWeek] && !!segmentsRecurrence?.[dayOfWeek]?.length,
      segments: getCurrentSegmentsRecur(segmentsRecurrence?.[dayOfWeek]),
    },
  ]
));

/**
 * Get the last date of the recurring appt
 *  Assuming has shape:
 * 'recurrence': [
 *     'RRULE:FREQ=WEEKLY;UNTIL=20221019T035959Z',
 *  ],
 * @param {Object} recurringAppt
 * @return {Date} end date of the recurring appt
 */
export const getRecurringApptEndDate = (recurringAppt) => {
  // new version of Create Api
  if (recurringAppt.intervalType) {
    if (recurringAppt.intervalType === APPT_INTERVAL_TYPE.FIXED) {
      return parseISO(recurringAppt.endDate);
    } else {
      return null;
    }
  }
  // Recurrence is old version of Create Api.
  // The following code will be removed after server updated the old data.
  const recurrenceRule = recurringAppt.recurrence[0];
  const shortISOPart = recurrenceRule.split('UNTIL=')[1];
  if (shortISOPart) {
    const shortISO = shortISOPart.split(';')[0];
    const res = parseISO(shortISO);
    return res;
  }
  return null;
};

/**
 * Returns array of appt instance ids that have starts and ends coinciding with
 *  a single day in this user's local timezone
 * @param {Array<DateTime>} dayInterval
 * @param {Array} apptsList
 * @return {Array<string>}
 */
export const getBoundingApptsFromListUsingInterval = (dayInterval, apptsList) => {
  const searchStart = dayInterval[0].startOf('day').toJSDate();
  const searchEnd = dayInterval[1].endOf('day').toJSDate();
  const apptBounds = searchIntervals(
    apptsList,
    searchStart,
    searchEnd,
    (appt) => new DateWithTimeZone(getStartDate(appt), getApptTimeZone(appt)),
    (appt) => new DateWithTimeZone(getEndDate(appt), getApptTimeZone(appt)),
  );
  return apptBounds;
};

/**
 * Returns array of appt instance ids that have starts and ends coinciding with
 *  a single day in the given datetime's timezone
 * @param {DateTime} day
 * @param {Array} apptsList
 * @return {Array<Appt>}
 */
export const getBoundingApptsFromList = (day, apptsList) => {
  const searchStart = day.startOf('day').toJSDate();
  const searchEnd = day.plus({days: 1}).startOf('day').toJSDate();
  const apptBounds = searchIntervals(
    apptsList,
    searchStart,
    searchEnd,
    (appt) => new DateWithTimeZone(getStartDate(appt), getApptTimeZone(appt)),
    (appt) => new DateWithTimeZone(getEndDate(appt), getApptTimeZone(appt)),
  );
  return apptBounds;
};

/**
 * add business days to date. ex date = 4/11/23, numDays = 6
 * return 4/19/23
 * @param {Number} numDays
 * @param {DateTime} date
 * @return {DateTime} date plus number of businessdays
 */
export const addBusinessDaysToDate = (numDays, date) => {
  let newDate = date;
  const numWeeks = Math.floor(numDays / 5);
  newDate = newDate.plus({weeks: numWeeks});
  const extraDays = numDays % 5;
  for (let i = 1; i <= extraDays; i++) {
    newDate = newDate.plus({days: 1});
    if (newDate.weekday === 6) {
      newDate = newDate.plus({days: 2});
    } else if (newDate.weekday === 7) {
      newDate = newDate.plus({days: 1});
    }
  }
  return newDate;
};

/**
 *
 * @param {Object} segment
 * @param {string} timeZone
 * @return {boolean}
 */
export const isWeekendInTz = (segment, timeZone) => {
  const weekdayInHostTz = toDateTime(segment.start, timeZone).weekday;
  return weekdayInHostTz === 6 || weekdayInHostTz === 7;
};

/**
 * minutes to days
 * @param {Number} minutes
 * @return {Number} number of days
 */
export const minutesToDay = (minutes) => {
  return Math.floor(minutes/DAY_MINUTES);
};

/**
 * Get the last date of an appointment
 *  Assuming appt has shape:
 * 'recurrence': [
 *     'RRULE:FREQ=WEEKLY;UNTIL=20221019T035959Z',
 *  ],
 * @param {Object} appt
 * @param {String} timeZone
 * @return {DateTime} end date of the recurring appt
 */
export const getApptEndDate = (appt, timeZone) => {
  let rollingWindowEndDate = null;
  let res;
  if (appt?.maxBookingTime && appt?.periodType) {
    // moving = calendar days, availableMoving = businessDays
    const now = DateTime.now().setZone(timeZone);
    const days = minutesToDay(appt.maxBookingTime);
    rollingWindowEndDate = (appt.periodType === PERIOD_TYPE.MOVING) ?
      now.plus({days: days}) :
      appt.periodType === PERIOD_TYPE.AVAILABLE_MOVING ?
      addBusinessDaysToDate(days, now) : null;
  }

  if (isApptRecurring(appt)) {
    // intervalType is new version of Create Api
    if (appt.intervalType && appt.intervalType === APPT_INTERVAL_TYPE.FIXED) {
      res = toDateTime(getEndDate(appt), timeZone);
      res = rollingWindowEndDate ? DateTime.min(res, rollingWindowEndDate) : res;
      return res.endOf('day');
    }

    // Recurrence is old version of Create Api.
    // The code of following 7 lines will be removed after server updated the old data.
    const recurrenceRule = appt.recurrence[0];
    const shortISOPart = recurrenceRule.split('UNTIL=')[1];
    if (shortISOPart) {
      const shortISO = shortISOPart.split(';')[0];
      res = toDateTime(parseISO(shortISO), timeZone);
      return rollingWindowEndDate ? DateTime.min(res, rollingWindowEndDate) : res;
    }

    const MAX_SERV_RECURRENCE = 719;
    // When no recurrence end is set, server will max out at 719 recurring instances:
    // (assumes each instance is 1 week long)
    res = toDateTime(getEndDate(appt), timeZone).plus({weeks: MAX_SERV_RECURRENCE-1});
  } else {
    res = toDateTime(getEndDate(appt), timeZone);
  }
  return rollingWindowEndDate ? DateTime.min(res, rollingWindowEndDate) : res;
};

/**
 * Get the end time of an event
 *  Assuming eventInfo has shape:
 * 'originalEvent': {
 *     'end': {
 *         'dateTime': '2022-04-29T01:15:00-04:00'
 *     },
 *  },
 * @param {Object} eventInfo
 * @return {Date} end date of the recurring appt
 */
export const getEventEndTime = (eventInfo) => {
  const endTime = eventInfo?.originalEvent?.end?.dateTime;
  return new Date(endTime);
};

/**
 * Get the booking limit of an appointment
 *  Assuming appointment has shape:
 *  {
 *     ...
 *     'bookingLimit': 6,
 *     ...
 *  }
 *  Backward compatible with old format:
 *  {
 *     ...
 *     'bookingLimit': {
 *         'enabled': true,
 *         'number': 6
 *     },
 *     ...
 *  }
 * @param {Object} appt
 * @return {Number} booking limit
 */
export const getBookingLimit = (appt) => {
  const limitEnabled = appt?.bookingLimit?.enabled;
  let bookingLimit = 0;
  if (limitEnabled == null) {
    bookingLimit = appt?.bookingLimit || 0;
  } else { // for backward compatibility
    bookingLimit = appt?.bookingLimit?.enabled ?
      appt?.bookingLimit?.number || 0 :
      0;
  }
  return {
    durationAmount: bookingLimit,
    durationUnit: appt?.bookingLimitUnit || BOOKING_LIMIT_TIME_WINDOW.DAY,
  };
};

export const getAttendeeLocationConfiguration = (attendee, appt) => {
  if (attendee.locationConfiguration) {
    return attendee.locationConfiguration;
  }

  if (attendee.addOnType) {
    return {
      kind: attendee.addOnType,
    };
  }
  if (attendee.location) {
    return {
      kind: ADD_ON_TYPE.IN_PERSON,
      location: attendee.location,
      additionalInfo: '',
    };
  }
  if (appt?.addOnType === ADD_ON_TYPE.ZOOM_MEETING) {
    return {
      kind: ADD_ON_TYPE.ZOOM_MEETING,
    };
  }
  if (appt?.location) {
    return {
      kind: ADD_ON_TYPE.IN_PERSON,
      location: appt?.location,
    };
  }
  return {
    kind: ADD_ON_TYPE.OFFLINE,
  };
};

export const getMultipleLocationSetting = (appt) => {
  if (appt?.locationConfigurations) {
    if (appt.locationConfigurations.length) {
      return appt.locationConfigurations.map((cfg) => {
        if (cfg.kind === ADD_ON_TYPE.IN_BOUND_CALL) {
          const [countryCode, phoneNumber] = cfg.phoneNumber.split(' ');
          return {
            ...cfg,
            hostPhoneCountryCode: countryCode,
            hostPhoneNumber: phoneNumber,
          };
        }
        return cfg;
      });
    }
  } else if (appt) {
    if (appt.addOnType === ADD_ON_TYPE.ZOOM_MEETING) {
      return [{kind: ADD_ON_TYPE.ZOOM_MEETING}];
    } else if (appt.location) {
      return [{kind: ADD_ON_TYPE.IN_PERSON, location: appt.location, additionalInfo: ''}];
    }
  }
  return [{kind: ADD_ON_TYPE.OFFLINE}];
};

/**
 * @typedef SplitBufferTime
 * @property {number} before
 * @property {number} after
 */

/**
 * Get the buffer time of an appointment
 *  Assuming appointment has shape:
 *  (newest split buffer format)
 *  {
 *     ...
 *     'buffer': {
 *       'before': 15,
 *       'after': 30,
 *     }
 *  }
 *  Backward compatible with formats:
 *  (old, shared buffer time)
 *  {
 *     ...
 *     'buffer': 15,
 *     ...
 *  }
 *  (oldest, shared buffer time with enabled flag)
 *  {
 *     ...
 *     'buffer': {
 *         'enabled': true,
 *         'minutes': 15
 *     },
 *     ...
 *  }
 * @param {Object} appt
 * @return {SplitBufferTime} buffer time in minutes split by before and after
 */
export const getBufferTime = (appt) => {
  let buffer = {before: 0, after: 0};
  const buf = appt?.buffer;
  if (typeof buf === 'number') {
    buffer = {before: buf, after: buf};
  } else if (buf !== null && typeof buf === 'object') {
    if (buf.before !== undefined && buf.after !== undefined) {
      buffer = buf;
    } else {
      // for backward compatibility
      const bufferAmt = buf.enabled ? (buf.minutes || 0) : 0;
      buffer = {before: bufferAmt, after: bufferAmt};
    }
  }
  return buffer;
};

/**
 * Get the cushion time of an appointment
 *  Assuming appointment has shape:
 *  {
 *     ...
 *     'cushion': 60,
 *     ...
 *  }
 *  Backward compatible with old format:
 *  {
 *     ...
 *     'cushion': {
 *         'enabled': true,
 *         'hours': 1
 *     },
 *     ...
 *  }
 * @param {Object} appt
 * @return {Number} cushion time in minutes
 */
export const getCushionTime = (appt) => {
  const cushionEnabled = appt?.cushion?.enabled;
  let cushion = 0;
  if (cushionEnabled == null) {
    cushion = appt?.cushion || 0;
  } else { // for backward compatibility
    cushion = appt?.cushion?.enabled ?
      appt?.cushion?.hours * HOUR_MINUTES || 0 :
      0;
  }
  return cushion;
};

/**
 * Whether or not a given date falls in the spanning start and end range of the apptList,
 *  ignoring individual segments
 * @param {Date} date
 * @param {string} timeZone
 * @param {Object} appt
 * @param {Date} cushionCutoff
 * @return {Boolean}
 */
export const isInApptSpanRange = (date, timeZone, appt, cushionCutoff) => {
  const cushionDt = toDateTime(cushionCutoff, timeZone);
  const zonedDt = toDateTime(date, timeZone, true).startOf('day');
  if (zonedDt < cushionDt.startOf('day')) {
    return false;
  }

  const end = getApptEndDate(appt, timeZone);
  try {
    return Interval.fromDateTimes(
      toDateTime(getStartDate(appt), timeZone).startOf('day'),
      end.endOf('day'),
    ).contains(zonedDt);
  } catch {
    return false;
  }
};

/**
 * The apptDays month availability key
 * @param {DateTime} dateTime
 * @param {string} timeZone
 * @return {string}
 */
export const getMonthAvailabilityKey = (dateTime, timeZone) => {
  return `${dateTime.toISODate()}-${timeZone}`;
};

/**
 * The apptDays week availability key
 * @param {DateTime} dateTime
 * @param {string} timeZone
 * @return {string}
 */
export const getWeekAvailabilityKey = (dateTime, timeZone) => {
  return `${getStartOfWeek(dateTime).toUTC().toISO()}&&&${timeZone}`;
};

export const weekKeyToDt = (weekKey) => {
  const parts = weekKey.split('&&&');
  const iso = parts[0];
  const timeZone = parts[2];
  return toDateTime(iso, timeZone);
};

/**
 * (Not in use right now, since we rely on the server to derive date availability)
 * Whether or not to show a given day as available on the calendar
 *  If the appt is non-recurring, uses segments
 *  If the appt is recurring, uses the appt start as well as recurrence UNTIL rule
 * @param {Date} date
 * @param {Object} appt
 * @param {DateTime} cushionCutoff
 * @param {Object} apptDays
 * @return {Boolean}
 */
export const isValidDate = (date, appt, cushionCutoff, apptDays) => {
  const timeZone = cushionCutoff.zoneName;
  const zonedDate = toDateTime(date, cushionCutoff.zoneName, true).startOf('day');
  if (zonedDate < cushionCutoff.startOf('day')) {
    return false;
  }
  const daysData = apptDays?.[getWeekAvailabilityKey(toDateTime(date, timeZone, true), timeZone)]?.days;
  if (daysData?.length) {
    const apptDay = daysData.find(
      (apptDay) => toDateTime(apptDay?.date, cushionCutoff.zoneName, true).hasSame(date, 'day'));
    if (apptDay?.status === AVAIL_STATUS.AVAILABLE) {
      return true;
    } else if (apptDay?.status === AVAIL_STATUS.UNAVAILABLE) {
      return false;
    }
  }
};

export const getDayClassName = (date, selectedDate, appt, cushionCutoff, apptDays) => {
  if (date.getTime() === new Date(selectedDate).getTime()) {
    return 'react-datepicker__day--selected';
  }
  const timeZone = cushionCutoff.zoneName;
  const zonedDate = toDateTime(date, cushionCutoff.zoneName, true).startOf('day');
  if (zonedDate < cushionCutoff.startOf('day')) {
    return 'day-unavailable';
  }
  const weekData = apptDays?.[getWeekAvailabilityKey(toDateTime(date, timeZone, true), timeZone)];
  const daysData = weekData?.days;
  if (daysData?.length) {
    const apptDay = daysData.find(
      (apptDay) => toDateTime(apptDay?.date, cushionCutoff.zoneName, true).hasSame(date, 'day'));
    if (apptDay?.status === AVAIL_STATUS.AVAILABLE) {
      return 'day-available';
    } else if (apptDay?.status === AVAIL_STATUS.UNAVAILABLE) {
      return 'day-unavailable';
    }
  }

  if (isTeamAppt(appt) && weekData?.loading) {
    // return 'skeleton-box';
    return 'day-loading';
  }
  return 'day-unavailable';
};

/**
 * Return true if slot with given start and end is free
 * @param {Object} appt server appt object
 * @param {DateTime} start candidate slot start
 * @param {DateTime} end candidate slot end
 * @param {Object} bookedSlotsByDay mapping from ISO8601 startOfDay in offset local timezone to array of booked slots
 * @return {Boolean} true if slot with start and end is available
 */
export const isAvailable = (appt, start, end, bookedSlotsByDay = {}) => {
  // if (!isInDayRange(start, appt) || !isInDayRange(end, appt)) {
  //   return false;
  // }

  if (
    start.valueOf() < new Date(getStartDate(appt)).valueOf() ||
    end.valueOf() > getApptEndDate(appt).valueOf()) {
    return false;
  }

  // (1:many booking) Booked slots with openings will appear in appt.busy but they should still be bookable.
  // if a bookedSlot exists matching the start and it has vacancies,
  // we can always mark it available, even if the bookingLimit is reached or busy checks violated
  const bookedSlots = appt?.bookedSlots || [];
  const bookedSlot = bookedSlots.find((slot) => {
    return (new Date(slot.start).valueOf() === start.valueOf());
  });
  if (bookedSlot && bookedSlot.availableNumber > 0) {
    return true;
  }
  const {durationAmount, durationUnit} = getBookingLimit(appt);

  if (durationAmount && durationUnit === BOOKING_LIMIT_TIME_WINDOW.DAY) {
    const organizerDay = toDateTime(start, getApptTimeZone(appt)).startOf('day');
    const dayISO = organizerDay.toUTC().toISO();
    const bookedSlotsForDay = bookedSlotsByDay[dayISO];
    if (bookedSlotsForDay && durationAmount <= bookedSlotsForDay.length) {
      // this day has reached its booking limit
      console.log('booking limit reached', start, dayISO);
      return false;
    }
  }
  // the day has not reached its booking limit, continue with normal busy check:

  // If buffer enabled, widen the candidate slot by "x" buffer amount on each side before checking conflicts:
  //         |-x-|-slot-|-x-|
  // |--busy1--|           |--busy2--|

  const buffer = getBufferTime(appt);
  const bufferedStart = start.plus({minutes: -1*buffer.before}).toJSDate();
  const bufferedEnd = end.plus({minutes: buffer.after}).toJSDate();

  const busyIntervals = appt.busy || [];
  const conflicts = getConflicts(
    busyIntervals,
    bufferedStart,
    bufferedEnd,
  );
  if (conflicts.length) {
    console.log(
      'slot:', start.toLocaleString(), '->', end.toLocaleString(),
      '\nconflicts', conflicts,
    );
  }
  return conflicts.length === 0;
};

/**
 * Return the number of vacancies of the given time slot
 * @param {Object} appt server appt object
 * @param {DateTime} start
 * @param {Object} bookedSlotsByStart indexed bookedSlots by their start time ISOString
 * @return {Number} the number of available number of spots
 */
export const getAvailableNumber = (appt, start, bookedSlotsByStart) => {
  let availableNumber = appt?.capacity || 1;

  const bookedSlot = bookedSlotsByStart[start.toUTC().toISO()];
  if (bookedSlot) {
    availableNumber = bookedSlot?.availableNumber;
  }
  return availableNumber;
};

/**
 * Return the first time slot of the segment starting on the given
 *  selectedDate
 * @param {DateTime} selectedDate
 * @param {*} segment
 * @param {Number} incrementMinutes the increment amount to offset each candidate slot by (in minutes)
 * @return {DateTime}
 */
export function getFirstSlotOfSegmentDay(selectedDate, segment, incrementMinutes) {
  const dayStart = selectedDate.startOf('day');
  const segStart = toDateTime(segment.start, dayStart.zoneName);
  if (dayStart > segStart) {
    // get the first value x such that the start time will be in the selected date.
    const x = Math.ceil(dayStart.diff(segStart, 'minutes').minutes / incrementMinutes);
    return segStart.plus({minutes: incrementMinutes * x});
  } else {
    return segStart;
  }
}

/**
 * Index the bookedSlots by the day they belong to in the organizer's time zone.
 * @param {Array} bookedSlots
 * @param {String} organizerTz appt organizer's IANA timezone
 * @return {Object} maps from startOfDay in the organizer's timezone as an ISOString -> array of slots
 */
export function groupBookedSlotsByDay(bookedSlots, organizerTz) {
  if (!bookedSlots || bookedSlots.length === 0) {
    return {};
  }
  const bookedSlotsByDate = {};
  bookedSlots.forEach((slot) => {
    const organizerDay = dayjs(slot.start).tz(organizerTz).startOf('day');
    const dayISO = organizerDay.toISOString();
    if (bookedSlotsByDate[dayISO]) {
      bookedSlotsByDate[dayISO].push(slot);
    } else {
      bookedSlotsByDate[dayISO] = [slot];
    }
  });
  return bookedSlotsByDate;
};

/**
 * (Not in use right now, since we rely on the server to derive time slot availability)
 * Derive the time slots for a date
 * @param {Object} appt
 * @param {Array<Object>} apptsList
 * @param {Date} selectedDate
 * @param {string} timeZone
 * @param {Boolean} isBookerVoting
 * @return {Array<Slot>}
 */
export function getTimesForDay(appt, apptsList, selectedDate, timeZone, isBookerVoting) {
  let maxBookingDate = null;

  const selectedDay = toDateTime(selectedDate, timeZone, true).startOf('day');
  const selectedDayInterval = Interval.fromDateTimes(
    selectedDay.startOf('day'),
    selectedDay.endOf('day'),
  );
  if (appt.periodType === PERIOD_TYPE.MOVING || appt.periodType === PERIOD_TYPE.AVAILABLE_MOVING) {
    maxBookingDate = getApptEndDate(appt);
    // do not add slots to date if rolling window is set and selectedDate is created than maxBookingDate
    if (selectedDay.valueOf() > maxBookingDate.valueOf()) {
      return [];
    }
  };
  const durationMinutes = appt.duration;
  const slots = [];
  const buffer = getBufferTime(appt);

  // "Show Slots Every" amount. If not present in the case of old appointments, use default durationMinutes + buffer.
  const incrementAmount = appt?.startTimeIncrement || (durationMinutes + buffer);

  let groupNum = 0;
  for (const apptInst of apptsList) {
    if (!isInDayRange(selectedDay, apptInst)) {
      return [];
    }

    if (isBookerVoting) {
      const spots = apptInst?.spots ? [...apptInst.spots] : [];
      for (const spot of spots) {
        const daysOverlap = Interval.fromDateTimes(new Date(spot.start), new Date(spot.end))
          .overlaps(selectedDayInterval);
        if (daysOverlap) {
          // TODO add groupNum support
          slots.push({
            start: new DateWithTimeZone(spot?.start, timeZone),
            ...(spot?.voters && {voters: spot.voters}),
          });
        }
      }
    } else {
      const days = apptInst?.days ? [...apptInst.days] : [];
      for (const day of days) {
        const currDay = toDateTime(day.date, getApptTimeZone(appt), true);
        const currDayEnd = currDay.endOf('day');
        const daysOverlap = Interval.fromDateTimes(currDay, currDayEnd).overlaps(selectedDayInterval);
        if (daysOverlap && day.status === AVAIL_STATUS.AVAILABLE) {
          const spots = day.spots.filter((spot) => {
            return selectedDayInterval.contains(toDateTime(spot.startTime));
          });
          for (let i = 0; i < spots.length; i++) {
            if (i !== 0) {
              // eslint-disable-next-line max-len
              const diffSlotTime = differenceInMinutes(new Date(spots[i]?.startTime), new Date(spots[i - 1]?.startTime));
              if (diffSlotTime > incrementAmount) {
                groupNum++;
              }
            }
            slots.push({
              apptId: apptInst.id,
              start: new DateWithTimeZone(spots[i]?.startTime, timeZone),
              groupNum: groupNum,
              availableNumber: spots[i]?.status === AVAIL_STATUS.AVAILABLE ? spots[i]?.availableNumber : 0,
              status: spots[i]?.status,
            });
          }
        }
      }
    }
  }
  return slots;
}

export const addVotersToDay = (appt) => {
  const voterSpots = appt?.spots || [];
  const days = appt?.days || [];
  const slots = [];
  for (let i = 0; i < days.length; i++) {
    const spots = days[i].spots.map((spot) => {
      const votes = voterSpots.find((voteSpot) => {
        return new Date(voteSpot.start).getTime() === new Date(spot?.startTime).getTime();
      });
      return {
        ...spot,
        voters: votes ? votes.voters : [],
      };
    });

    slots.push({
      ...days[i],
      spots: spots,
    });
  }
  return slots;
};

export const getSpotsForDay = (appt, day) => {
  const durationMinutes = appt.duration;
  const slots = [];
  const buffer = getBufferTime(appt);
  const spots = day.spots;
  // "Show Slots Every" amount. If not present in the case of old appointments, use default durationMinutes + buffer.
  const incrementAmount = appt?.startTimeIncrement || (durationMinutes + buffer);
  let groupNum = 0;
  for (let i = 0; i < spots.length; i++) {
    if (i !== 0) {
      // eslint-disable-next-line max-len
      const diffSlotTime = differenceInMinutes(new Date(spots[i]?.startTime), new Date(spots[i - 1]?.startTime));
      if (diffSlotTime > incrementAmount) {
        groupNum++;
      }
    }
    slots.push({
      apptId: spots[i].instanceId,
      start: new Date(spots[i]?.startTime),
      groupNum: groupNum,
      availableNumber: spots[i]?.status === AVAIL_STATUS.AVAILABLE ? spots[i]?.availableNumber : 0,
      status: spots[i]?.status,
      voters: spots[i]?.voters || [],
      conflicts: spots[i].conflicts?.filter((conflict) => {
        // if ignoreBusyEvent is set, busy events on calendar will not block the time slots
        if ((appt?.ignoreBusyEvent) && conflict.code === CONFLICT_CODE.CALENDAR) {
          return false;
        }
        return true;
      }),
      teamPresenceInfo: spots[i]?.teamPresenceInfo,
    });
  }
  return slots;
};

/**
 * @param {Array<Object>} availableSlots
 * @param {Date} currentTimeCutoff
 * @param {Date} bookedSlotStart
 * @return {Array<Slot>}
 */
export function getCushionedTimesForDay(availableSlots, currentTimeCutoff, bookedSlotStart) {
  const firstAvailableIndex = availableSlots.findIndex(({start}) => {
    return currentTimeCutoff < start || bookedSlotStart.valueOf() === start?.valueOf();
  });

  let cushionedAvailableTimes = [];
  if (firstAvailableIndex >= 0) {
    availableSlots = availableSlots.map((slot) => {
      if (currentTimeCutoff < slot.start) {
        return {
          apptId: slot.apptId,
          availableNumber: slot.availableNumber,
          groupNum: slot.groupNum,
          start: slot.start,
          status: slot.status,
        };
      }
      return {
        apptId: slot.apptId,
        availableNumber: slot.availableNumber,
        groupNum: slot.groupNum,
        start: slot.start,
        status: AVAIL_STATUS.UNAVAILABLE,
      };
    });
    cushionedAvailableTimes = availableSlots.slice(firstAvailableIndex).filter((slot) => {
      return (
        (slot.status === AVAIL_STATUS.AVAILABLE) ||
        (bookedSlotStart.valueOf() === slot.start?.valueOf())
      );
    });
  }

  return cushionedAvailableTimes;
}

const instantiateSegs = (appt, timeMin, timeMax, timeZone) => {
  if (getApptIntervalType(appt)) {
    // derive the recurring appointment instance segments
    const interval = Interval.fromDateTimes(
      toDateTime(toDateTime(new Date(timeMin), timeZone).startOf('day'), getApptTimeZone(appt)).startOf('day'),
      toDateTime(toDateTime(new Date(timeMax), timeZone).endOf('day'), getApptTimeZone(appt)).startOf('day'),
    );
    // const dateTimes = [
    //   toDateTime(zonedDate.startOf('day'), getApptTimeZone(appt)),
    //   toDateTime(zonedDate.endOf('day'), getApptTimeZone(appt)),
    // ];
    const dateTimes = interval.splitBy({day: 1}).map((int) => int.start);

    const dates = dateTimes.map((dt) => dt.startOf('day'));
    const segInsts = dates.reduce((prev, currDt) => {
      const dayKey = DAY_KEYS[currDt.weekday-1];
      if (appt?.periodType === PERIOD_TYPE.AVAILABLE_MOVING && ['Sat', 'Sun'].includes(dayKey)) {
        // If the day is a weekend in the HOST's timezone + rolling business days,
        // don't allow weekends
        return prev;
      }
      const apptOverrides = appt?.segments || [];
      const overridesSorted = [...apptOverrides];
      overridesSorted.sort((a, b) => compareAsc(new Date(a.start), new Date(b.start)));
      // Since getConflicts is not inclusive, subtract 1s from currDt
      // so that override unavailable segments will be caught as a conflict
      const overrideSegments = getConflicts(
        overridesSorted, new Date(currDt.toJSDate()-1), currDt.endOf('day').toJSDate()
      );
      const isOverrideUnavailable = overrideSegments.some(
        (seg) => seg.start === seg.end && new Date(seg.start).valueOf() === currDt.toJSDate().valueOf()
      );
      if (isOverrideUnavailable) {
        // Day is marked override Unavailable
        return prev;
      }
      // Since 1s was subtracted earlier, filter out segments that belong to the previous day.
      const overrideTimes = overrideSegments.filter((seg) => new Date(seg.end) > currDt.toJSDate());
      if (overrideTimes.length > 0) {
        // Add the override segments and ignore recurring segs for this day.
        return prev.concat(overrideSegments);
      }
      const recSegs = appt?.segmentsRecurrence?.[dayKey] || [];
      const instantiateTime = (timeStr, currDt) => {
        const [hr, min] = timeStr.split(':').map((x) => Math.floor(parseFloat(x)));
        return currDt.set({hour: hr, minute: min});
      };
      const segInst = recSegs.map((s) => {
        return {
          start: instantiateTime(s.start, currDt).toISO(),
          end: instantiateTime(s.end, currDt).toISO(),
        };
      });
      return prev.concat(segInst);
    }, []);
    return segInsts;
  } else {
    return appt?.segments || [];
  }
};

/**
 * Helper function to mock server's Appointment Instances .days field
 * @param {Object} appt
 * @param {String} timeMin
 * @param {String} timeMax
 * @param {String} timeZone
 * @return {Array<Day>}
 */
export function getDaysHandler(appt, timeMin, timeMax, timeZone) {
  const durationMinutes = appt.duration;
  const slots = [];
  const buffer = getBufferTime(appt);

  const spots = appt?.spots || [];

  // "Show Slots Every" amount. If not present in the case of old appointments, use default durationMinutes + buffer.
  const incrementAmount = appt?.startTimeIncrement || (durationMinutes + buffer.before);

  const segs = instantiateSegs(appt, timeMin, timeMax, timeZone);
  segs.sort((a, b) =>
    compareAsc(
      new Date(a.start),
      new Date(b.start),
    )
  );

  for (const segment of segs) {
    const segEnd = toDateTime(segment.end, timeZone|| getApptTimeZone(appt));

    let slotStart = toDateTime(segment.start, timeZone || getApptTimeZone(appt));
    while (slotStart < segEnd && slotStart.plus({minutes: durationMinutes}) <= segEnd) {
      if (
        isAvailable(appt, slotStart, slotStart.plus({minutes: durationMinutes}))
      ) {
        const slotStartDt = toDateTime(new Date(slotStart));
        slots.push({
          startTime: new Date(slotStart).toISOString(),
          availableNumber: appt.capacity,
          status: AVAIL_STATUS.AVAILABLE,
          instanceId: !!getApptIntervalType(appt) ?
            `${appt.id}_${slotStartDt.year}-${slotStartDt.weekNumber}` : appt.id,
        });
      }
      slotStart = slotStart.plus({minutes: incrementAmount});
    }
  }

  for (const spot of spots) {
    const segEnd = toDateTime(spot.end, timeZone|| getApptTimeZone(appt));

    let slotStart = toDateTime(spot.start, timeZone || getApptTimeZone(appt));
    while (slotStart < segEnd && slotStart.plus({minutes: durationMinutes}) <= segEnd) {
      if (
        isAvailable(appt, slotStart, slotStart.plus({minutes: durationMinutes}))
      ) {
        const slotStartDt = toDateTime(new Date(slotStart));
        slots.push({
          startTime: new Date(slotStart).toISOString(),
          availableNumber: appt.capacity,
          status: AVAIL_STATUS.AVAILABLE,
          instanceId: !!getApptIntervalType(appt) ?
            `${appt.id}_${slotStartDt.year}-${slotStartDt.weekNumber}`: appt.id,
        });
      }
      slotStart = slotStart.plus({minutes: incrementAmount});
    }
  }

  const days = [];
  for (const slot of slots) {
    const slotStart = toDateTime(slot.startTime, timeZone || getApptTimeZone(appt));
    const slotDay = slotStart.startOf('day').toFormat('yyyy-LL-dd');
    if (days[days.length - 1]?.date !== slotDay) {
      days.push({date: slotDay, spots: [slot], status: AVAIL_STATUS.AVAILABLE});
    } else {
      days[days.length - 1].spots.push(slot);
    }
  }
  return days;
}

/**
 * @typedef BusyInterval
 * @type {object}
 * @property {string} start iso date string
 * @property {string} end iso date string
 */

/**
 * Gets an array of conflicting intervals given slotStart and slotEnd dates
 * @param {Array<Object>} intervals array of intervals to search through
 * @param {Date} slotStart
 * @param {Date} slotEnd
 * @param {Function} getIntervalStart maps an interval to its start value as a DateTime
 * @param {Function} getIntervalEnd maps an interval to its end value as a DateTime
 * @return {Array<Object>} intervals that coincide with the given start and end
 */
function searchIntervals(intervals, slotStart, slotEnd, getIntervalStart, getIntervalEnd) {
  /**
   * Two time intervals conflict / overlap if:
   *   slotStart <= busyEnd AND slotEnd >= busyStart
   * Then to find conflicts out of list of sorted intervals, busyIntervals:
   *   binary search for first busyInterval with end larger than slotStart
   *   binary search for first busyInterval with start smaller than slotStart
   */
  const fn = compareAsc;

  // get first busyInterval with slotStart <= end time
  const lowerBound = binarySearchUpperBound(
    intervals,
    fn,
    0,
    intervals.length,
    slotStart,
    getIntervalEnd,
  );
  // get last busyInterval with slotEnd >= start time
  const upperBound = binarySearchLowerBound(
    intervals,
    fn,
    lowerBound,
    intervals.length,
    slotEnd,
    getIntervalStart,
  );

  return intervals.slice(lowerBound, upperBound);
}


/**
 * Gets an array of conflicting busy intervals given slotStart and slotEnd dates
 * @param {Array<BusyInterval>} busyIntervals array of busy intervals
 * @param {Date} slotStart
 * @param {Date} slotEnd
 * @return {Array<BusyInterval>}
 */
function getConflicts(busyIntervals, slotStart, slotEnd) {
  /**
   * Two time intervals conflict / overlap if:
   *   slotStart <= busyEnd AND slotEnd >= busyStart
   * Then to find conflicts out of list of sorted intervals, busyIntervals:
   *   binary search for first busyInterval with end larger than slotStart
   *   binary search for first busyInterval with start smaller than slotStart
   */
  const fn = compareAsc;

  // get first busyInterval with slotStart <= end time
  const lowerBound = binarySearchUpperBound(
    busyIntervals,
    fn,
    0,
    busyIntervals.length,
    slotStart,
    (item) => new Date(item.end)
  );
  // get last busyInterval with slotEnd >= start time
  const upperBound = binarySearchLowerBound(
    busyIntervals,
    fn,
    lowerBound,
    busyIntervals.length,
    slotEnd,
    (item) => new Date(item.start)
  );
  return busyIntervals.slice(lowerBound, upperBound);
}

/**
 *
 * @param {*} items
 * @param {*} compareFn
 * @param {*} left
 * @param {*} right
 * @param {*} target
 * @param {Function} getKey function to map item to search compare value
 * @return {number}
 */
function binarySearchLowerBound(items, compareFn, left, right, target, getKey) {
  while (left < right) {
    const mid = (left + right) >> 1;
    if (compareFn(getKey(items[mid]), target) < 0) {
      left = mid + 1;
    } else {
      // Also when equal...
      right = mid;
    }
  }
  return left;
}

/**
 *
 * @param {*} items
 * @param {*} compareFn
 * @param {*} left
 * @param {*} right
 * @param {*} target
 * @param {Function} getKey function to map item to search compare value
 * @return {number}
 */
function binarySearchUpperBound(items, compareFn, left, right, target, getKey) {
  while (left < right) {
    const mid = (left + right) >> 1;
    if (compareFn(getKey(items[mid]), target) <= 0) {
      // Also when equal...
      left = mid + 1;
    } else {
      right = mid;
    }
  }
  return left;
}

/**
 * check is share with me appt
 * @param {Appt} appt
 * @param {?string} calId
 * @return {boolean}
 */
export const isSharedWithMe = (appt, calId) => {
  const currentEmail = (calId) ? calId.toLowerCase() : getUserInfo()?.calendarId?.toLowerCase();
  const hostEmail = appt.organizer?.email;
  const apptHost = appt.attendees?.find((user) => user.email === hostEmail);
  let isSharedWithMe = apptHost && hostEmail !== currentEmail;
  if (isTeamAppt(appt)) {
    const myHost = appt.attendees?.find((user) => user.email === currentEmail);
    isSharedWithMe = isSharedWithMe && myHost?.host === false;
  }
  return isSharedWithMe;
};

/**
 * match create appt error message
 * @param {String} message
 * @return {boolean}
 */
export const createApptErrorMsgMatches = (message) => {
  const errorMsgs = [
    'Invalid recurrence start time',
    'Invalid recurrence UNTIL time',
    'Availability rule invalid',
    'Availability rule not exist',
    'Availability rule missing some of the attendees',
    'Non-shared type does not support availability override',
    'Failed to insert random slug b/c conflict with zlkp',
  ];
  return errorMsgs.map((el) => el.toLowerCase()).includes(message.toLowerCase());
};

export const formatApptDisplayLocation = (appt) => {
  const locations = appt.locationConfigurations;
  let location;
  let addOnType;
  if (locations) { // new multiple location
    // if more than one location, should not show location, needs to be determined when book
    if (locations.length === 1) {
      addOnType = locations[0].kind;
      if (addOnType === ADD_ON_TYPE.IN_PERSON || (addOnType === ADD_ON_TYPE.CUSTOM && !locations[0].hideLocation)) {
        location = locations[0].location;
      }
    }
  } else { // legacy location
    location = appt.location;
    addOnType = getAddOnType(appt);
  }
  return {addOnType, location};
};
