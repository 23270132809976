/* eslint-disable */
import {delCookie, getCookie} from './CookieUtil.js';
import hybrid from '@zoom/hybrid-jssdk';

import {OFFICE365_ADDIN_LINK, OFFICE365_SDK_LINK} from 'Utils/consts.js';

import {nanoid} from '@reduxjs/toolkit';
import { deferred } from './index.js';
import {global as Bus} from './EventBus';
import { initHybridAppBridge } from './initHybridAppBridge.js';

export class ZoomSchedulerSDKBase {
  initOffice() {
    throw new Error('the initOffice method is yet to be implemented.');
  }
  getSchedulerToken() {
    throw new Error('the getSchedulerToken method is yet to be implemented.');
  }
  logout() {
    throw new Error('the logout method is yet to be implemented.');
  }
  openDialog() {
    throw new Error('the openDialog method is yet to be implemented.');
  }
  setInvitation() {
    throw new Error('the setInvitation method is yet to be implemented.');
  }
}

export class ZoomSchedulerSDK extends ZoomSchedulerSDKBase {
  constructor() {
    super();
    this.zoomSchedulerToken = '';
    this.popupDialog = null;
  }

  initOffice() {
    const historyCache = {
      replaceState: window.history.replaceState,
      pushState: window.history.pushState,
    };
    const script = document.createElement('script');
    script.src = OFFICE365_SDK_LINK;
    script.onload = () => {
      Office && Office?.onReady(() => {
        // Office js deletes window.history.pushState and window.history.replaceState. Restore them
        window.history.replaceState = historyCache.replaceState;
        window.history.pushState = historyCache.pushState;
        // if (officeHost.context?.ui?.addHandlerAsync) {
        //   officeHost.context.ui.addHandlerAsync(officeHost?.EventType?.DialogParentMessageReceived, this.onMessageFrom365);
        // }
      });
    };
    document.head.appendChild(script);
  };

  getSchedulerToken() {
    const zoomSchedulerToken = getCookie('zsak');
    if (zoomSchedulerToken) {
      this.zoomSchedulerToken = zoomSchedulerToken;
    }

    return this.zoomSchedulerToken;
  };

  logout() {
    delCookie('zak');
    delCookie('zsak');
    window.location.href = OFFICE365_ADDIN_LINK + '/login?redirect=scheduler&signout=true';
  };

  openDialog(
    startAddress, options = {height: 75, width: 70}, msgHandler, eventHandler) {
    const self = this;
    // eslint-disable-next-line
    function dialogCallBack(result) {
      if (result.status == 'succeeded') {
        self.popupDialog = result.value;

        if (self.popupDialog != null && msgHandler && eventHandler) {
          self.popupDialog.addEventHandler(Office.EventType.DialogMessageReceived, msgHandler);
          self.popupDialog.addEventHandler(Office.EventType.DialogEventReceived, eventHandler);
        } else {
        }
      } else {
        console.error('Error in displaying the dialog', result);
      }
    }
    if (typeof Office.context.ui.displayDialogAsync === 'function') {
      Office.context.ui.displayDialogAsync(startAddress, options, dialogCallBack);
    } else {
      window.open(startAddress, '_blank');
    }
  };

  setInvitation(invitation, callback) {
    // const self = this;
    return new Office.Promise(function() {
      Office.context.mailbox.item.body.getTypeAsync(function(result) {
        if (result.status == Office.AsyncResultStatus.Failed) {
          Office.context.mailbox.item.body.setAsync(invitation, {coercionType: Office.CoercionType.Text}, callback);
        } else {
          Office.context.mailbox.item.body.setAsync(invitation, {coercionType: result.value}, callback);
        }
      });
    });
  };
};

export class ZoomMailSchedulerSDK extends ZoomSchedulerSDKBase {
  callbackPromises = {};

  constructor() {
    super();
    this.initHybridAppBridgeListeners();

    this.originalWindowOpen = window.open;
    this.handleClick = this.handleClick.bind(this);
    this.handleWindowOpen = this.handleWindowOpen.bind(this);

    window.addEventListener('click', this.handleClick, true);
    
    window.open = this.handleWindowOpen;
  }

  async initHybridAppBridgeListeners() {
    const instance = hybrid.bridge.getInstance();
    instance.registerSpecificNotificationHandler(
      ['DLEv_RecvWebviewMessage'],
      (notificationData) => {
        try {
          const result = notificationData.result.eventData.result;
          const data = JSON.parse(result);
          this.handlerOnMessage(data);
        } catch (err) {
          console.error('DLEv_RecvWebviewMessage notification error', err);
        }
      });
  }

  handleClick(event) {
    let target = event.target.closest('a');
    if (target && target.tagName === 'A') {
        event.preventDefault();
        this.openDialog(target.href);
    }
  }

  handlerOnMessage(data) {
    console.log('[zoomSchedulerSDK] handlerOnMessage: ', data);
    if (data?.from !== 'mail-client') {
      return;
    }
    this.handlerResolveMessage(data);
  }

  async handlerResolveMessage(data) {
    const {type} = data;
    
    if (type === 'refreshToken') {
      Bus.emit('refreshToken', {target: {info: {sak: data.sak}}});
      return;
    } else if (type === 'ajaxResult') {
      const {id, result, error, success} = data;
      if (this.callbackPromises[id]) {
        const {resolve, reject} = this.callbackPromises[id];
        if (success) {
          resolve(result);
        } else {
          reject(new Error(error));
        }
        delete this.callbackPromises[id];
      }
    } else if (type === 'close') {
      window.close();
    }
  }

  async postMessage(data, id) {
    try {
      const appBridge = await initHybridAppBridge();
      const service = appBridge.getService({ service: 'unifywebview' });
      await service.pub({
        action: 'DLAct_SendWebviewMessage',
        params: {
          webviewName: 'Mail',
          data: JSON.stringify({...data, id, from: 'scheduler'}),
        }
      })
    } catch(err) {
      console.error('[SchedulerSDK] postMessage error: ', err);
    }
  }

  postMessageWithCallback(data) {
    const uuid = nanoid();
    this.postMessage(data, uuid);
    const {promise, reject, resolve} = deferred();

    const settimeId = setTimeout(() => {
      reject(new Error('timeout'));
    }, 60000);

    promise.finally(() => clearTimeout(settimeId));

    this.callbackPromises[uuid] = {
      reject,
      resolve
    };

    return promise;
  }

  initOffice() {
    return;
  }

  async getSchedulerToken() {
    try {
      return await this.postMessageWithCallback({type: 'getSchedulerToken'});
    } catch(error) {
      console.error(error);
      return null;
    }
  }

  logout() {
    throw new Error('the logout method is yet to be implemented.');
  }

  openDialog(startAddress) {
    this.postMessage({
      type: 'openUrlInDefaultBrowser',
      link: startAddress
    });
  }

  handleWindowOpen(url, ...args) {
    this.openDialog(url);
    return null;
}

  setInvitation(invitation, callback) {
    this.postMessageWithCallback({
      type: 'insertContent',
      content: invitation,
    }).then(res => {
      callback?.(res);
    }).catch(err => {
      console.err(err);
    }) 
  }

  close() {
    window.close();
  }

  destroy() {
    window.removeEventListener('click', this.handleClick, true);
    window.open = this.originalWindowOpen; // recover original window.open
  }
}

let ZoomSchedulerSDKClient = {};
if (window?.location?.href?.indexOf('clientType=calFor365') > 0) {
  ZoomSchedulerSDKClient = new ZoomSchedulerSDK();
} else if (window?.location?.href?.indexOf('from=mail-client') > 0) {
  ZoomSchedulerSDKClient = new ZoomMailSchedulerSDK();
}

// const ZoomSchedulerSDKClient = window?.location?.href?.indexOf('clientType=calFor365') > 0 ? new ZoomSchedulerSDK() : {};
export default ZoomSchedulerSDKClient;
