import React from 'react';

import {
  Dialog,
  Menu,
  MenuItem,
} from '@mui/material';
import {styled} from '@mui/system';

export const ZmMenuItemPrimary = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    'fontSize': '14px',
    // 'color': theme.palette.common.swatch['90'],
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1.5),
    },
    '&:hover': {
      'backgroundColor': theme.palette.primary.main,
      'color': `${theme.palette.common.white}`,
      '.MuiTypography-root': {
        color: `${theme.palette.common.white}`,
      },
      '& .text': {
        'color': `${theme.palette.common.white}`,
      },
    },
    '&:active': {
      'backgroundColor': theme.palette.primary.main,
      'color': `${theme.palette.common.white}`,
      '.MuiTypography-root': {
        color: `${theme.palette.common.white}`,
      },
    },
  },
}));

/**
 * ZmMenu styled component wrapper around MUI Menu
 * @param {MenuProps} props
 * @return {ReactElement}
 */
export const ZmMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    'border': `1px solid ${theme.palette.common.swatch['30']}`,
    'borderRadius': 8,
    'minWidth': 180,
    'boxShadow': '0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1)',
    '& .MuiMenu-list': {
      padding: '2px 0',
    },
    '& .MuiMenuItem-root': {
      'fontSize': '14px',
      // 'color': theme.palette.common.swatch['90'],
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        'backgroundColor': theme.palette.primary.main,
        'color': `${theme.palette.common.white}`,
        '.MuiTypography-root': {
          color: `${theme.palette.common.white}`,
        },
      },
      '&:active': {
        'backgroundColor': theme.palette.primary.main,
        'color': `${theme.palette.common.white}`,
        '.MuiTypography-root': {
          color: `${theme.palette.common.white}`,
        },
      },
    },
  },
}));

// remove withDialogPositionAdjust HOC
// because dialog can now automatically appear in the center of the window as much as possible
// even if the page is in an iframe

/**
 * ZmDialog Component wrapper around MUI Dialog
 * @param {DialogProps} props
 * @return {ReactElement}
 */
export const ZmDialog = styled(Dialog)(({theme}) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  '.MuiDialog-paper:not(.MuiAlert-root)': {
    'border': `1px solid ${theme.palette.common.swatch['30']}`,
    'borderRadius': '16px',
    'boxShadow': '0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1)',
    'padding': 0,
  },
  '.MuiDialogTitle-root': {
    padding: '28px 24px',
  },
  '.MuiDialogContent-root': {
    padding: '0px 24px 8px 24px',
  },
  '.MuiDialogActions-root': {
    padding: '16px 24px 24px 24px',
  },
}));


/**
 * ZmDialog Component wrapper around MUI Dialog
 * @param {DialogProps} props
 * @return {ReactElement}
 */
export const ZmDialogSplitView = styled(Dialog)(({theme}) => ({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  '.MuiPaper-root:not(.MuiAlert-root)': {
    'border': `1px solid ${theme.palette.common.swatch['30']}`,
    'borderRadius': '16px',
    'boxShadow': '0px 12px 24px rgba(19, 22, 25, 0.1), 0px 6px 12px rgba(19, 22, 25, 0.1)',
    'padding': 0,
  },
  '.MuiDialogTitle-root': {
    padding: '28px 24px',
  },
  '.MuiDialogContent-root': {
    padding: '0',
  },
  '.MuiDialogActions-root': {
    padding: '16px 24px 24px 24px',
  },
}));
