import CryptoJS from 'crypto-js';

// 16 bytes AES-128 key
const key = CryptoJS.enc.Utf8.parse('d824464d3b2045ea');

const AES_CONFIG = {
  // 16 bytes AES-128 iv
  iv: CryptoJS.enc.Utf8.parse('938fb59ca778c08e'),
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
};

export const encryptAES = (plaintext) => {
  const encrypted = CryptoJS.AES.encrypt(plaintext, key, AES_CONFIG);
  return encrypted.toString();
};

export const decryptAES = (ciphertext) => {
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, AES_CONFIG);
  return decrypted.toString(CryptoJS.enc.Utf8);
};
