import {SvgIcon} from '@mui/material';

import {ReactComponent as AlertIcon} from 'assets/svg/alert-filled.svg';
import {ReactComponent as CheckMarkIcon} from 'assets/svg/checkmark-filled.svg';
import {ReactComponent as WarningIcon} from 'assets/svg/warning-alert.svg';
import {lightenDarkenColor} from 'Utils';

export const zoomPalette = {
  gray: {
    10: '#F7F9FA',
    20: '#F1F4F6',
    30: '#DFE3E8',
    40: '#C1C6CE',
    50: '#98A0A9',
    60: '#6E7680',
    70: '#444B53',
    80: '#252A30',
    90: '#131619',
    100: '#090B0D',
    110: '#323539',
  },
  white: '#FFFFFF',
  black: '#232333',
  lightBlue: '#f2f2f7',
  blue: {
    10: '#F2F8FF',
    20: '#D0E3FB',
    30: '#A8CCF8',
    40: '#7AB1F4',
    50: '#4793F1',
    60: '#0E72ED',
    70: '#0956B5',
    80: '#033A7D',
    90: '#001F45',
    100: '#00060D',
    110: '#2057B1',
  },
  orange: {
    10: '#FFF7F2',
    20: '#FFD1B9',
    30: '#FFAB81',
    40: '#FF8548',
    50: '#FF5F0F',
    60: '#CF4B0A',
    70: '#9E3806',
    80: '#6E2603',
    90: '#3D1501',
    100: '#0D0400',
  },
  red: {
    10: '#FFF2F5',
    20: '#FAD1D9',
    30: '#F6AAB8',
    40: '#F17E93',
    50: '#ED4D6A',
    60: '#E8173D',
    70: '#B10E2C',
    80: '#7A051B',
    90: '#44010D',
    100: '#0D0002',
  },
  gold: {
    10: '#FFF9F2',
    20: '#F0DCC4',
    30: '#E1BD93',
    40: '#D19F61',
    50: '#C28030',
    60: '#B36200',
    70: '#894C01',
    80: '#603400',
    90: '#361E00',
    100: '#0D0700',
  },
  green: {
    10: '#F2FFF6',
    20: '#C9E7D2',
    30: '#9ECEAD',
    40: '#74B688',
    50: '#4B9D64',
    60: '#268543',
    70: '#17672F',
    80: '#0A491D',
    90: '#022B0F',
    100: '#000D04',
  },
};

export const ALERT_ICON_MAPPING = {
  success: <SvgIcon sx={{width: '16px'}} fontSize="small" component={CheckMarkIcon} inheritViewBox
    htmlColor={zoomPalette.green[60]} />,
  warning: <SvgIcon sx={{width: '16px'}} fontSize="small" component={WarningIcon} inheritViewBox
    htmlColor={zoomPalette.gold[60]} />,
  error: <SvgIcon fontSize="small" component={AlertIcon} inheritViewBox htmlColor={zoomPalette.red[60]} />,
};

export const getDesignTokens = (embedWidgetParams, bookingPageColor = '', fontFamily = '') => {
  let embedWidgetStyle = {};
  try {
    embedWidgetStyle = JSON.parse(embedWidgetParams);
  } catch (error) {}
  const {backgroundColor, buttonColor, fontSize, textColor} = embedWidgetStyle;
  document.documentElement.style.fontSize = `${100 * (fontSize || 100) / 100}%`;
  return {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            fontFamily: ${fontFamily || '' } 'Almaden Sans', 'Inter', 'Roboto', '-apple-system', 'BlinkMacSystemFont',
              'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans',
              'Helvetica Neue', 'sans-serif', 'Arial';
            letter-spacing: 0.03em;
          }
          * {
            boxSizing: 'border-box';
          }
        `,
      },
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiAlert: {
        defaultProps: {
          iconMapping: ALERT_ICON_MAPPING,
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            lineHeight: '20px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: zoomPalette.red[60],
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: zoomPalette.gray['10'],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            'boxShadow': 'unset',
            'textTransform': 'unset',
            'fontWeight': 'bold',
            'height': 48,
            'borderRadius': 12,
            'flexShrink': 0,
            '&:hover': {
              boxShadow: 'unset',
              backgroundColor: backgroundColor? lightenDarkenColor(backgroundColor, 10) : undefined,
            },
            '&:active': {
              boxShadow: 'unset',
            },
            '&.Mui-focusVisible': {
              boxShadow: '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #0E72ED',
            },
          },
          startIcon: {
            '& > *:nth-of-type(1)': {
              fontSize: 'inherit',
            },
          },
          sizeLarge: {
            height: 60,
            borderRadius: 15,
            minWidth: 60,
          },
          sizeSmall: {
            height: 32,
            borderRadius: 8,
            padding: '6px 16px',
            fontSize: '.875rem',
            lineHeight: '1.25rem',
            fontWeight: '500',
            minWidth: 32,
          },
          sizeXsmall: {
            height: 24,
            borderRadius: 6,
            fontSize: '.875rem',
            lineHeight: '1.25rem',
            padding: '2px 8px',
            fontWeight: '500',
            minWidth: 24,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            // copying chromes default outline style
            '&:focus-visible': {
              outlineColor: '#0E72ED',
              outlineOffset: '1px',
              outlineStyle: 'auto',
              outlineWidth: '1px',
              borderRadius: '4px',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            // copying chromes default outline style
            '&:focus-visible': {
              outlineColor: '#0E72ED',
              outlineStyle: 'auto',
              outlineWidth: '1px',
              borderRadius: '4px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ownerState}) => ({
            ...(!ownerState?.multiline && {
              height: 40,
            }),
            borderRadius: 10,
          }),
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '11px',
              marginTop: '20px',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              fontWeight: 600,
            },
            '& .MuiDataGrid-overlayWrapper': {
              height: 'auto !important',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              height: 'auto !important',
            },
            'borderRadius': '10px',
          },
          row: {
            '&.Mui-selected': {
              'backgroundColor': zoomPalette.blue[60],
              'color': zoomPalette.white,
              '&:hover': {
                backgroundColor: zoomPalette.blue[60],
              },
            },
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
    },
    overrides: {
      // MuiCssBaseline: {
      //   'styleOverrides': {
      //     'body': {
      //       backgroundColor: zoomPalette.gray['20'],
      //       fontFamily: [
      //         'Almaden Sans',
      //         'Inter',
      //         'Roboto',
      //         '-apple-system',
      //         'BlinkMacSystemFont',
      //         'Segoe UI',
      //         'Roboto',
      //         'Oxygen',
      //         'Ubuntu',
      //         'Cantarell',
      //         'Open Sans',
      //         'Helvetica Neue',
      //         'sans-serif',
      //         'Arial',
      //       ].join(','),
      //       letterSpacing: '.03em';
      //     },
      //     '*': {
      //       boxSizing: 'border-box',
      //     },
      //   },
      // },
      MuiInputBase: {
        root: {
          'borderRadius': 'inherit',
          'width': 'fit-content',
          '&$disabled': {
            backgroundColor: zoomPalette.gray['10'],
            color: zoomPalette.gray['60'],
          },
        },
      },
    },
    typography: {
      fontFamily: [
        fontFamily || '',
        'Almaden Sans',
        'Inter',
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Open Sans',
        'Helvetica Neue',
        'sans-serif',
        'Arial',
      ].filter((i) => !!i).join(','),
      letterSpacing: '.03em',
      useNextVariants: true,
    },
    palette: {
      text: {
        primary: textColor || zoomPalette.gray['90'],
        secondary: textColor? lightenDarkenColor(textColor, 10) : zoomPalette.black,
        tertiary: textColor? lightenDarkenColor(textColor, 90): zoomPalette.gray[60],
        strongerNeutral: zoomPalette.gray['110'],
      },
      common: {
        white: zoomPalette.white,
        black: zoomPalette.black,
        swatch: {...zoomPalette.gray},
      },
      primary: {
        light: zoomPalette.blue['50'],
        main: zoomPalette.blue['60'],
        dark: zoomPalette.blue['70'],
        altLight: zoomPalette.blue['10'],
        altDark: zoomPalette.blue['30'],
        swatch: {...zoomPalette.blue},
      },
      secondary: {
        light: zoomPalette.orange['50'],
        main: zoomPalette.orange['60'],
        dark: zoomPalette.orange['70'],
        altLight: zoomPalette.orange['10'],
        altDark: zoomPalette.orange['30'],
        swatch: {...zoomPalette.orange},
      },
      error: {
        light: zoomPalette.red['30'],
        main: zoomPalette.red['60'],
        dark: zoomPalette.red['70'],
        swatch: {...zoomPalette.red},
      },
      background: {
        default: backgroundColor || zoomPalette.white,
        hover: backgroundColor? lightenDarkenColor(backgroundColor, 20) : zoomPalette.gray['20'],
        active: backgroundColor? lightenDarkenColor(backgroundColor, 30) : zoomPalette.gray['30'],
      },
      button: {
        default: buttonColor || bookingPageColor || zoomPalette.blue['60'],
        hover: buttonColor ?
          lightenDarkenColor(buttonColor, 30) :
          (bookingPageColor ? lightenDarkenColor(bookingPageColor, 30) : zoomPalette.blue['70']),
        active: buttonColor ?
        lightenDarkenColor(buttonColor, 40) :
          (bookingPageColor ? lightenDarkenColor(bookingPageColor, 40) : zoomPalette.blue['80']),
      },
    },
  };
};

export default zoomPalette;
