import {createSlice} from '@reduxjs/toolkit';

export const themeState = createSlice({
  name: 'themeStore',
  initialState: {
    fontFamily: '',
  },
  reducers: {
    updateFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    },
  },
});

export const {
  updateFontFamily,
} = themeState.actions;

export default themeState.reducer;
