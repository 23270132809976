import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {addDays, compareAsc, isValid, startOfMonth} from 'date-fns';
import {DateTime, Interval} from 'luxon';

import {NWS_ERROR_CODE} from './IntegrationStore';
import {setDomainLogoExt} from './LogoStore';

import {fetchProfilePicture, getDisplayName, getExternalUserInfo} from 'Api/Calendar';
import {toDateTime} from 'Components/BookingBlocksWidget/utils';
import {CaptchaError} from 'Error/CaptchaError';
import {
  ErrorMsgBody404,
  ErrorMsgBody500,
  ErrorMsgBody500BadCalendar,
} from 'Error/ErrorMsgConst';
import {HttpError} from 'Error/HttpError';
import {expandMonthToWeeks, expandRangeToWeeks, getApptType, getEndOfWeek, getStartOfWeek, mergeDaysData} from 'Utils';
import {
  addVotersToDay,
  getApptIntervalType,
  getApptEndDate,
  getWeekAvailabilityKey,
  getStartDate,
  isTeamAppt,
  weekKeyToDt,
} from 'Utils/apptAvailabilityUtils';
import {APPT_TYPE, EVENT_STATUS} from 'Utils/consts';
import {decryptAES} from 'Utils/crypto';

const initialState = {
  appt: undefined,
  troubleShootState: {},
  apptDays: {
    /**
     * format follows:
     * [firstDayIso]: {
     *  loading: false,
     *  error: false,
     *  data: null,
     * }
     */
  },
  bookingPageColor: '',
  showPoweredByZoom: true,
  loadingInitialData: false,
  loadingUserData: false,
  errorState: { // to store page-level errors
    isError: false,
    reason: '',
  },
  currentTimeCutoff: new Date().toISOString(),
  selectedDate: undefined,
  viewingMonth: undefined,
  createrDisplayName: '',
  profilePicture: {
    errorState: {
      isError: false,
      reason: '',
    },
    id: '',
    value: {
      data: '',
    },
  },
  url: {
    hasInitialLoad: false,
    queryTz: undefined,
    querySelectedSlot: undefined,
  },
  routingMetricState: {},
  firstLoad: true,
  seqNo: 0,
};

/**
 * @param {?string} errMsg
 * @param {function} t
 * @return {string}
 */
export const get400ErrorReason = (errMsg) => {
  const errorMessage = errMsg || '';
  if (errorMessage.match(/Invalid email/i)) {
    return 'booking.fetchErrorInvalidEmail';
  } else if (errorMessage.match(/Link has already been used/i)) {
    return 'booking.fetchErrorLinkUsed';
  }
  return 'booking.fetchErrorGeneric';
};

/**
 * @param {?string} errMsg
 * @return {boolean}
 */
const isHostConnectionError = (errMsg) => {
  const errorMessage = errMsg || '';
  if (
    errorMessage.match(/No pushEnabled connection/i) ||
    errorMessage.match(/failed to.*freebusy/i) ||
    errorMessage.match(/connection is empty/i) ||
    errorMessage.match(/Invalid authorization/i) ||
    errorMessage.match(new RegExp(`(${Object.values(NWS_ERROR_CODE).join('|')})`))
  ) {
    return true;
  }
  return false;
};


/**
 * @typedef Query
 * @property {Function} query
 * @property {Array} args
 */

/**
 *
 * @param {Query} query
 * @return {*}
 */
const executeQuery = (query) => {
  return query.query(...query.args);
};


export const fetchInitialApptData = createAsyncThunk(
  'bookAppointments/getInitialAppts',
  /**
   * Fetches the initial appt data and sets the initial selected date + slot according to the
   * given query params
   * @param {Object} thunkParams
   * @param {Query} thunkParams.fetchApptAvailQuery
   * @param {Query} thunkParams.fetchApptQuery
   * @param {?URLSearchParams} thunkParams.queryParams
   * @param {*} thunkAPI
   * @return {Promise}
   */
  async ({
    fetchApptAvailQuery,
    fetchApptQuery,
    queryParams,
  }, thunkAPI) => {
    try {
      const initialApptResp = await executeQuery(fetchApptQuery);
      const timeZone = fetchApptAvailQuery.args[2]?.timeZone;
      // if ?slot encoded, only show the time slots added to the email instead of all available times
      const encodedSlot = queryParams?.get('encodedQuerySlot');
      // if the recurring appt is cancelled, treat it as a one-off appt
      if (getApptIntervalType(initialApptResp) && initialApptResp.status !== EVENT_STATUS.CANCELLED) {
        thunkAPI.dispatch(setAppt(initialApptResp));
        const querySelectedSlot = encodedSlot? decryptAES(encodedSlot): queryParams?.get('slot');
        if (querySelectedSlot) {
          try {
            const slotDate = toDateTime(querySelectedSlot, timeZone);
            if (querySelectedSlot && slotDate.isValid) {
              thunkAPI.dispatch(setSelectedDate(new Date(querySelectedSlot).toISOString()));
              const availability = await thunkAPI.dispatch(fetchApptAvailableTimes({
                fetchApptAvailQuery: {
                  query: fetchApptAvailQuery.query,
                  args: [
                    fetchApptAvailQuery.args[0],
                    initialApptResp.id,
                    {
                      timeMin: getStartOfWeek(slotDate).toUTC().toISO(),
                      timeMax: getEndOfWeek(slotDate).toUTC().toISO(),
                      timeZone: timeZone,
                    },
                  ],
                },
              })).unwrap();
              const simplifiedDateString = slotDate.toFormat('yyyy-MM-dd');
              const selectedDay = (availability.days || []).find((day) => {
                return day.date === simplifiedDateString;
              });
              const selectedSpot = (selectedDay?.spots || []).find((spot) => {
                return (new Date(spot.startTime).valueOf() === slotDate.valueOf());
              });
              return selectedSpot;
            };
          } catch {}
        }
        // Fall back to setting initial date normally.
        const initialDateInZone = DateTime.max(
          DateTime.now().setZone(timeZone).startOf('day'),
          toDateTime(getStartDate(initialApptResp), timeZone).startOf('day'),
        );
        const dateInLocal = initialDateInZone.setZone('local', {keepLocalTime: true});
        thunkAPI.dispatch(setSelectedDate(dateInLocal.toUTC().toISO()));
      } else {
        if (initialApptResp.busy) {
          initialApptResp.busy.sort((a, b) =>
            compareAsc(
              new Date(a.start),
              new Date(b.start)
            )
          );
        } else {
          initialApptResp.busy = [];
        }
        // since the appt is non-recurring, no more fetching necessary, we can
        // just populate the store
        thunkAPI.dispatch(setAppt(initialApptResp));
        const initialDateInZone = DateTime.max(
          DateTime.now().setZone(timeZone).startOf('day'),
          toDateTime(getStartDate(initialApptResp), timeZone).startOf('day'),
        );
        const dateInLocal = initialDateInZone.setZone('local', {keepLocalTime: true});
        thunkAPI.dispatch(setSelectedDate(dateInLocal.toUTC().toISO()));
      }
    } catch (e) {
      console.error(e);
      // Redux toolkit does not support throwing non-serializable data like classes.
      // This causes us to lose error class instance information outside of the thunk context,
      // so we need to handle the error and return the customized error reason here
      let reason = '';
      // See src/mocks/testcases/errors.js for expected error formats
      if (e instanceof CaptchaError) {
        reason = e.message;
      } else if (e instanceof HttpError) {
        switch (e.errorCode) {
          case 400:
            reason = get400ErrorReason(e.body?.error?.message);
            break;
          case 403:
            const serverErrMsg = e.body?.error?.message || '';
            // Scheduler license revoked / expired
            if (serverErrMsg.match(/Permission deny/i)) {
              reason = ErrorMsgBody500BadCalendar;
            }
            break;
          case 404:
            if (e.message === 'Invalid host') {
              throw e;
            }
            reason = ErrorMsgBody404;
            break;
          case 500:
            const errMsg = e.body?.error?.message || '';
            if (isHostConnectionError(errMsg)) {
              reason = ErrorMsgBody500BadCalendar;
            } else {
              reason = ErrorMsgBody500;
            }
            break;
          default:
            reason = e.body?.message || 'An error occurred';
            break;
        }
      } else {
        reason = 'An error occurred';
      }

      throw new Error(reason);
    }
  }
);

export const seqFetchApptAvailableTimes = createAsyncThunk(
  'bookAppointments/getApptAvailableTimesSeq',
  /**
   * Sequentially fetch each week of the given time span
   * @param {Object} thunkParams
   * @param {Query[]} thunkParams.fetchApptQueries
   * @param {number} thunkParams.seqNo
   * @param {*} thunkAPI
   * @return {Promise}
   */
  async ({
    fetchApptQueries,
    seqNo,
  }, thunkAPI) => {
    try {
      for (const fetchApptAvailQuery of fetchApptQueries) {
        if (thunkAPI.getState().bookAppointments.seqNo !== seqNo) {
          // stop making requests if the seqNo no longer matches
          return;
        }
        await thunkAPI.dispatch(fetchApptAvailableTimes({
          fetchApptAvailQuery: {
            query: fetchApptAvailQuery.query,
            args: fetchApptAvailQuery.args,
          },
          seqNo: seqNo,
        })).unwrap();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
);

export const fetchApptAvailableTimes = createAsyncThunk(
  'bookAppointments/getApptAvailableTimes',
  /**
   *
   * @param {Object} thunkParams
   * @param {Query} thunkParams.fetchApptAvailQuery
   * @param {number} thunkParams.seqNo
   * @param {*} thunkAPI
   * @return {Promise}
   */
  async ({
    fetchApptAvailQuery,
  }, thunkAPI) => {
    try {
      const apptResp = await executeQuery(fetchApptAvailQuery);
      if (getApptType(apptResp) === APPT_TYPE.POLL) {
        const withVotes = addVotersToDay(apptResp);
        return {...apptResp, days: withVotes};
      } else {
        return apptResp;
      }
    } catch (e) {
      console.error('fetchApptAvailableTimes', e);
      // Redux toolkit does not support throwing non-serializable data like classes.
      // This causes us to lose error class instance information outside of the thunk context,
      // so we need to handle the error and return the customized error reason here
      let reason = '';
      // See src/mocks/testcases/errors.js for expected error formats
      if (e instanceof CaptchaError) {
        reason = e.message;
      } else if (e instanceof HttpError) {
        switch (e.errorCode) {
          case 400:
            reason = e.body?.message || 'Invalid link, please check again or contact the host';
            break;
          case 403:
            const serverErrMsg = e.body?.error?.message || '';
            // Scheduler license revoked / expired
            if (serverErrMsg.match(/Permission deny/i)) {
              reason = ErrorMsgBody500BadCalendar;
            }
            break;
          case 404:
            if (e.message === 'Invalid host') {
              throw e;
            }
            reason = ErrorMsgBody404;
            break;
          case 500:
            const errMsg = e.body?.error?.message || '';
            if (isHostConnectionError(errMsg)) {
              reason = ErrorMsgBody500BadCalendar;
            } else {
              reason = ErrorMsgBody500;
            }
            break;
          default:
            reason = e.body?.message || 'An error occurred';
            break;
        }
      } else {
        reason = 'An error occurred';
      }

      throw new Error(reason);
    }
  }
);

export const fetchCreatorDisplayName = createAsyncThunk(
  'bookAppointments/getCreatorDisplayName',
  async (user) => {
    return await getDisplayName(user);
  }
);

export const fetchExternalUserInfo = createAsyncThunk(
  'bookAppointments/fetchExternalUserInfo',
  async (user, {dispatch}) => {
    try {
      const res = await getExternalUserInfo(user);
      dispatch(setDomainLogoExt({...res.logo}));
      return res;
    } catch (error) {
      dispatch(setDomainLogoExt({error: true}));
      throw error;
    }
  }
);

export const fetchAvailability = createAsyncThunk(
  'bookAppointments/fetchAvailability',
  /**
   * Fetches the missing availability in a batch or sequentially by week depending on if the
   * appt is team or not
   * @param {Object} thunkParams
   * @param {Query} thunkParams.fetchApptAvailQuery
   * @param {*} thunkAPI
   * @return {Promise}
   */
  async ({
    fetchApptAvailQuery,
  }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const appt = state.bookAppointments.appt;
      const initialSeqNo = thunkAPI.getState().bookAppointments.seqNo;
      if (isTeamAppt(appt)) {
        const timeZone = fetchApptAvailQuery.args[2].timeZone;
        const timeMin = toDateTime(fetchApptAvailQuery.args[2].timeMin, timeZone);
        const timeMax = toDateTime(fetchApptAvailQuery.args[2].timeMax, timeZone);
        const weekKeys = expandRangeToWeeks(timeMin, timeMax, timeZone)
          .filter((weekStart) => weekStart.diff(getApptEndDate(appt, timeZone)).milliseconds < 0)
          .map((weekStart) => getWeekAvailabilityKey(weekStart, timeZone));

        const apptDays = state.bookAppointments.apptDays;
        const missingWeekKeys = weekKeys.filter((key) =>
          !apptDays?.[key]?.error &&
          !apptDays?.[key]?.loading &&
          !apptDays?.[key]?.days
        );
        const queries = missingWeekKeys.map((key) => {
          const queryTimeMin = weekKeyToDt(key);
          const queryTimeMax = queryTimeMin.plus({week: 1});
          const splitArgs = [...fetchApptAvailQuery.args];
          splitArgs[2] = {...splitArgs[2]};
          splitArgs[2].timeMin = queryTimeMin.toUTC().toISO();
          splitArgs[2].timeMax = queryTimeMax.toUTC().toISO();
          return {
            query: fetchApptAvailQuery.query,
            args: splitArgs,
          };
        });

        return await thunkAPI.dispatch(seqFetchApptAvailableTimes({
          fetchApptQueries: queries,
          seqNo: initialSeqNo,
        })).unwrap();
      } else {
        return await thunkAPI.dispatch(fetchApptAvailableTimes({
          fetchApptAvailQuery,
          seqNo: initialSeqNo,
        })).unwrap();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
);

/**
 * Select the appt days availability for the current month view
 * @param {*} s redux store
 * @param {string} timeZone
 * @return {Array} the server day availability data for the given date-timeZone combination
 */
export const selectMonthAvailability = (s, timeZone) => {
  const viewingMonth = s.bookAppointments.viewingMonth;
  const weekKeys = expandMonthToWeeks(viewingMonth, timeZone).map((dt) => getWeekAvailabilityKey(dt, timeZone));
  const res = [];
  for (const weekKey of weekKeys) {
    res.push(s.bookAppointments.apptDays?.[weekKey] || {
      loading: false,
      error: false,
      days: null,
    });
  }
  return res;
};

/**
 * Select the appt days availability for the current month view
 * @param {*} s redux store
 * @param {Date} date
 * @param {string} timeZone
 * @return {Array} the server day availability data for the given date-timeZone combination
 */
export const selectWeekAvailability = (s, date, timeZone) => {
  const weekKey = getWeekAvailabilityKey(toDateTime(date, timeZone), timeZone);
  return s.bookAppointments.apptDays?.[weekKey] || {
    loading: false,
    error: false,
    days: null,
  };
};

/**
 *
 * @param {Object} apptDays the local store of cached availability
 * @param {luxon.DateTime} dateTime
 * @param {string} timeZone
 * @return {Object} the server day availability data for the given date-timeZone combination
 */
export const getDayAvailability = (apptDays, dateTime, timeZone) => {
  const daysData = apptDays?.[getWeekAvailabilityKey(
    toDateTime(dateTime, timeZone, true),
    timeZone)]?.days || [];
  const dateTimeDate = dateTime.toFormat('yyyy-LL-dd');
  const day = daysData.find((day) => day.date === dateTimeDate);
  return day;
};

export const listProfile = createAsyncThunk(
  'bookAppointments/getProfile',
  async (user) => {
    return await fetchProfilePicture(user);
  }
);

export const bookAppointmentsStore = createSlice({
  name: 'bookAppointmentsStore',
  initialState,
  reducers: {
    setAppt: (state, action) => {
      if (state?.appt?.days) {
        state.appt = {
          ...state.appt,
          days: mergeDaysData(state.appt.days, action?.payload?.days),
        };
      } else {
        state.appt = action.payload;
      }
    },
    setTroubleShootState: (state, action) => {
      state.troubleShootState.attendees = action.payload.attendees;
    },
    setRoutingMetricState: (state, action) => {
      state.routingMetricState = action.payload;
    },
    setSelectedDate: (state, action) => {
      const givenDate = new Date(action.payload);
      if (isValid(givenDate)) {
        state.selectedDate = givenDate.toISOString();
        state.viewingMonth = startOfMonth(givenDate).toISOString();
      } else {
        console.error('reducer ignoring invalid date');
        return;
      }
    },
    setViewingMonth: (state, action) => {
      const givenDate = new Date(action.payload);
      if (isValid(givenDate)) {
        state.viewingMonth = startOfMonth(givenDate).toISOString();
      } else {
        console.error('reducer ignoring invalid month');
        return;
      }
    },
    incrementSelectedDate: (state, action) => {
      const numDays = action.payload;
      const newSelectedDate = addDays(new Date(state.selectedDate), numDays).toISOString();
      state.selectedDate = newSelectedDate;
      state.viewingMonth = startOfMonth(new Date(newSelectedDate)).toISOString();
    },
    decrementSelectedDate: (state, action) => {
      const numDays = action.payload;
      const newSelectedDate = addDays(new Date(state.selectedDate), -1 * numDays).toISOString();
      state.selectedDate = newSelectedDate;
      state.viewingMonth = startOfMonth(new Date(newSelectedDate)).toISOString();
    },
    resetApptStore: (state) => {
      const initialState = {
        appt: undefined,
        troubleShootState: {},
        apptDays: {
          /**
           * format follows:
           * [firstDayIso]: {
           *  loading: false,
           *  error: false,
           *  data: null,
           * }
           */
        },
        loadingInitialData: false,
        loadingUserData: false,
        errorState: { // to store page-level errors
          isError: false,
          reason: '',
        },
        currentTimeCutoff: new Date().toISOString(),
        selectedDate: undefined,
        createrDisplayName: '',
        bookingPageColor: '',
      };
      Object.keys(initialState).forEach((stateKey) => {
        state[stateKey] = initialState[stateKey];
      });
      state.seqNo = state.seqNo + 1;
    },
    resetApptsDetailsState: (state, action) => {
      state.troubleShootState = {};
      state.apptDays = {};
      state.loadingInitialData = false;
      // Increment the seqNo to mark in-flight requests as stale
      state.seqNo = state.seqNo + 1;
    },
    setCurrentTimeCutoff: (state, action) => {
      state.currentTimeCutoff = action.payload;
    },
    setApptError: (state, action) => {
      state.errorState.isError = action.payload.isError;
      state.errorState.reason = action.payload.reason;
    },
    clearApptError: (state) => {
      state.errorState.isError = false;
      state.errorState.reason = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreatorDisplayName.fulfilled, (state, action) => {
      state.createrDisplayName = action.payload?.value ?? '';
    });
    builder.addCase(fetchExternalUserInfo.pending, (state, action) => {
      state.loadingUserData = true;
    });
    builder.addCase(fetchExternalUserInfo.fulfilled, (state, action) => {
      console.log(action.payload['displayName']?.value ?? '');
      state.createrDisplayName = action.payload['displayName']?.value ?? '';
      state.profilePicture = action.payload['picture'];
      if (action.payload['bookingPageColor']?.value) {
        state.bookingPageColor = action.payload['bookingPageColor']?.value;
      }
      state.showPoweredByZoom = action.payload['showPoweredByZoom'];
      state.loadingUserData = false;
    });
    builder.addCase(fetchExternalUserInfo.rejected, (state, action) => {
      state.loadingUserData = false;
    });
    builder.addCase(listProfile.fulfilled, (state, action) => {
      state.profilePicture = action.payload;
    });

    builder.addCase(seqFetchApptAvailableTimes.pending, (state, action) => {
      for (const fetchApptAvailQuery of action.meta.arg.fetchApptQueries) {
        const timeZone = fetchApptAvailQuery.args[2].timeZone;
        const timeMin = toDateTime(fetchApptAvailQuery.args[2].timeMin, timeZone);
        const timeMax = toDateTime(fetchApptAvailQuery.args[2].timeMax, timeZone);
        const weekIntervals = Interval.fromDateTimes(timeMin, timeMax).splitBy({week: 1});

        // Even though we are fetching each week span sequentially,
        // mark each as loading up-front to prevent duplicate
        for (const interval of weekIntervals) {
          state.apptDays[getWeekAvailabilityKey(interval.start, timeZone)] = {
            loading: true,
            error: false,
            days: null,
          };
        }
      }
    });

    builder.addCase(seqFetchApptAvailableTimes.rejected, (state, action) => {
      for (const fetchApptAvailQuery of action.meta.arg.fetchApptQueries) {
        const timeZone = fetchApptAvailQuery.args[2].timeZone;
        const timeMin = toDateTime(fetchApptAvailQuery.args[2].timeMin, timeZone);
        const timeMax = toDateTime(fetchApptAvailQuery.args[2].timeMax, timeZone);
        const weekIntervals = Interval.fromDateTimes(timeMin, timeMax).splitBy({week: 1});

        // Even though we are fetching each week span sequentially,
        // mark each as loading up-front to prevent duplicate
        for (const interval of weekIntervals) {
          state.apptDays[getWeekAvailabilityKey(interval.start, timeZone)] = {
            loading: false,
            error: true,
            days: null,
          };
        }
      }
    });

    builder.addCase(fetchApptAvailableTimes.pending, (state, action) => {
      // Stop fulfilling the requests if the seqNo no longer matches
      if (state.seqNo !== action.meta.arg.seqNo) {
        return;
      }
      const timeZone = action.meta.arg.fetchApptAvailQuery.args[2].timeZone;
      const timeMin = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMin, timeZone);
      const timeMax = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMax, timeZone);
      const weekIntervals = Interval.fromDateTimes(timeMin, timeMax).splitBy({week: 1});

      for (const interval of weekIntervals) {
        state.apptDays[getWeekAvailabilityKey(interval.start, timeZone)] = {
          loading: true,
          error: false,
          days: null,
        };
      }
    });

    builder.addCase(fetchInitialApptData.fulfilled, (state, action) => {
      state.loadingInitialData = false;
    });
    builder.addCase(fetchInitialApptData.pending, (state, action) => {
      state.loadingInitialData = true;
    });
    builder.addCase(fetchInitialApptData.rejected, (state, action) => {
      state.loadingInitialData = false;
    });

    builder.addCase(fetchApptAvailableTimes.fulfilled, (state, action) => {
      // Stop fulfilling the requests if the seqNo no longer matches
      if (state.seqNo !== action.meta.arg.seqNo) {
        return;
      }
      const timeZone = action.meta.arg.fetchApptAvailQuery.args[2].timeZone;
      const timeMin = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMin, timeZone);
      const timeMax = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMax, timeZone);
      const weekIntervals = Interval.fromDateTimes(timeMin, timeMax).splitBy({week: 1});
      const rangeKeys = new Set(
        weekIntervals.map((interval) => getWeekAvailabilityKey(interval.start, timeZone))
      );
      for (const interval of weekIntervals) {
        state.apptDays[getWeekAvailabilityKey(interval.start, timeZone)] = {
          loading: false,
          error: false,
          days: [],
        };
      }

      const apptDayAvailability = action.payload?.days || [];
      const daysByWeek = {};
      // Even though we are fetching each week span sequentially,
      // mark each as loading up-front to prevent duplicate
      for (const day of apptDayAvailability) {
        const weekKey = getWeekAvailabilityKey(toDateTime(day.date, timeZone, true), timeZone);
        if (rangeKeys.has(weekKey)) {
          if (!daysByWeek[weekKey]) {
            daysByWeek[weekKey] = [{...day}];
          } else {
            daysByWeek[weekKey] = [...daysByWeek[weekKey], {...day}];
          }
        }
      }
      for (const key of Object.keys(daysByWeek)) {
        state.apptDays[key] = {
          loading: false,
          error: false,
          days: daysByWeek[key],
          // Hotfix: read smsNotification from getAvailTimes resp
          // Remove once smsNotification readable from getAppt resp
          smsEnabled: action.payload?.smsNotification,
        };
      }
    });

    builder.addCase(fetchApptAvailableTimes.rejected, (state, action) => {
      // Stop fulfilling the requests if the seqNo no longer matches
      if (state.seqNo !== action.meta.arg.seqNo) {
        return;
      }

      state.errorState = {
        isError: true,
        reason: action.error?.message,
      };

      const timeZone = action.meta.arg.fetchApptAvailQuery.args[2].timeZone;
      const timeMin = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMin, timeZone);
      const timeMax = toDateTime(action.meta.arg.fetchApptAvailQuery.args[2].timeMax, timeZone);
      const weekIntervals = Interval.fromDateTimes(timeMin, timeMax).splitBy({week: 1});
      for (const interval of weekIntervals) {
        state.apptDays[getWeekAvailabilityKey(interval.start, timeZone)] = {
          loading: false,
          error: true,
          days: [],
        };
      }
    });
  },
});

export const {
  clearApptError,
  decrementSelectedDate,
  incrementSelectedDate,
  resetApptStore,
  resetApptsDetailsState,
  setAppt,
  setApptError,
  setCurrentTimeCutoff,
  setRoutingMetricState,
  setSelectedDate,
  setTroubleShootState,
  setViewingMonth,
} = bookAppointmentsStore.actions;

export default bookAppointmentsStore.reducer;
