import React from 'react';

import {ThemeProvider as EThemeProvider} from '@emotion/react';
import {createTheme, ThemeProvider} from '@mui/material';
import {useSelector} from 'react-redux';

import App from './App';
import {getDesignTokens} from './zoomPalette';

import {embedWidgetParams} from 'Utils/integration';

/**
 *
 * @return {ReactElement}
 */
export default function ThemedApp() {
  const bookingPageColor = useSelector((s) => s.bookAppointments.bookingPageColor);
  const fontFamily = useSelector((s) => s.themeState.fontFamily);
  const muiVTheme = React.useMemo(
    () =>
      createTheme(
        getDesignTokens(embedWidgetParams, bookingPageColor, fontFamily)
      ),
    [bookingPageColor, fontFamily]
  );
  return (
    <ThemeProvider theme={muiVTheme}>
      <EThemeProvider theme={muiVTheme}>
        <App/>
      </EThemeProvider>
    </ThemeProvider>
  );
}
