import {useEffect, useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {AVAIL_STATUS} from './consts';
import {decryptAES} from './crypto';
import {getUserInfo} from './integration';

import {
  fetchUserProfileNameSyncLockSetting,
  fetchUserProfilePicSyncLockSetting,
  patchUserSyncLock,
} from 'Store/HostSettingsStore';

// profileSyncLock has account level value and user level value, if they don't match,
// we should patch the user level value automatically to make sure it's under account admin's control.
export const useFixProfileSync = () => {
  const dispatch = useDispatch();
  const userProfilePicSyncLock = useSelector((state) => state.hostSettingsState.userProfilePicSyncLock);
  const userProfileNameSyncLock = useSelector((state) => state.hostSettingsState.userProfileNameSyncLock);

  const adminControlUseWebProfile = useSelector((state) => {
    return (state.hostSettingsState.profilePicture.hasLoaded &&
        !state.hostSettingsState.profilePicture.adminProfileSyncLocked) ||
    (state.hostSettingsState.displayName.hasLoaded && !state.hostSettingsState.displayName.adminProfileSyncLocked);
  });
  useEffect(() => {
    dispatch(fetchUserProfilePicSyncLockSetting());
    dispatch(fetchUserProfileNameSyncLockSetting());
  }, [dispatch]);

  useEffect(() => {
    if (adminControlUseWebProfile) {
      if (userProfilePicSyncLock === 'true') {
        dispatch(patchUserSyncLock({
          type: 'pic',
          value: 'false',
        }));
      }
      if (userProfileNameSyncLock === 'true') {
        dispatch(patchUserSyncLock({
          type: 'name',
          value: 'false',
        }));
      }
    }
  }, [dispatch, userProfilePicSyncLock, userProfileNameSyncLock, adminControlUseWebProfile]);
};

export const useDetectZoomLanguage = () => {
  const {i18n} = useTranslation();
  const webPortalLanguage = useSelector((state) => state.integrationState.webPortalLanguage);
  useEffect(() => {
    // _zm_lang is injected by Zoom Client
    if (window.__system_params__?._zm_lang) {
      i18n.changeLanguage(window.__system_params__._zm_lang);
    } else if (webPortalLanguage) {
      i18n.changeLanguage(webPortalLanguage);
    } else {
      const {language} = getUserInfo() || {};
      if (language) {
        i18n.changeLanguage(language);
      }
    }
  }, [i18n, webPortalLanguage]);
};

export const useRecommendAvailableSlots = () => {
  const [searchParams] = useSearchParams();
  const encodedSelectedSlot = searchParams.get('slt');
  const allTimeSlotsStr = searchParams.get('all');

  const apptDays = useSelector((s) => s.bookAppointments.apptDays);

  const availableTimeSlots = useMemo(() => {
    if (allTimeSlotsStr) {
      const selectedSlot = decryptAES(encodedSelectedSlot);
      // build a map of `key: time slot ISO string` / `value: available/unavailable`
      const timeSlotAvailabilityMapping = Object.values(apptDays).reduce((res, cur) => res.concat(cur.days || []), [])
        .reduce((res, cur) => res.concat(cur.spots), [])
        .reduce((res, cur) => {
          res[new Date(cur.startTime).toISOString()] = cur.status;
          return res;
        }, {});
      // all time slots added to email
      const allTimeSlots = decryptAES(allTimeSlotsStr).split(',');
      // exclude the current time slot and unavailable time slots
      return allTimeSlots.filter((slot) => {
        return slot !== selectedSlot && timeSlotAvailabilityMapping[slot] !== AVAIL_STATUS.UNAVAILABLE;
      });
    }
    return [];
  }, [apptDays, allTimeSlotsStr, encodedSelectedSlot]);
  return availableTimeSlots;
};
